import { InputHelper } from "@services/input-helper";
import { MasterData } from "@services/master.data";
import { Cost, ServiceOptionCost } from "@wearewarp/types/data-model";

export interface FinDisplayCostItem {
  name: string, rate: string, qty: string, total: string, type?: string
}

export class FinUtil {
  static buildDisplayTransitCost(cost: Cost): FinDisplayCostItem {
    return {
      name: 'Transit Cost',
      rate: InputHelper.formatMoney2(`${cost?.rate ?? 0}`, '$'),
      qty: `${cost?.qty ?? 0}`,
      total: InputHelper.formatMoney2(`${cost?.total ?? 0}`, '$')
    };
  }

  static buildDisplayFuelCost(cost: Cost): FinDisplayCostItem {
    const type = cost?.type == 'rpm' ? 'RPM' : (cost?.type == 'percentage' ? 'Percentage' : cost?.type);
    const rate = cost?.type == 'rpm' ? InputHelper.formatMoney2(`${cost?.rpm ?? 0}`, '$') : `${cost?.percentage}% transit cost`;
    const qty = `${cost?.qty ?? ''}`;
    const total = InputHelper.formatMoney2(`${cost?.total ?? 0}`, '$');
    return {name: 'Fuel Cost', type, rate, qty, total}
  }

  static buildDisplayVolumeDiscount(cost: Cost): FinDisplayCostItem {
    const type = cost?.type == 'percentage' ? 'Percentage' : (cost?.type == 'flatRate' ? 'Flat Rate' : cost?.type);
    const rate = cost?.type == 'flatRate' ? InputHelper.formatMoney2(`${cost?.flatRate ?? 0}`, '$') : `${cost?.percentage ?? 0}% transit cost`;
    const total = InputHelper.formatMoney2(`${cost?.total ?? 0}`, '$');
    return {name: 'Volume Discount', type, rate, total, qty: '1'}
  }

  static buildDisplayServiceOption(options: ServiceOptionCost[]): FinDisplayCostItem[] {
    return (options ?? []).map(it => {
      const isNegative = MasterData.isServiceOptionTypeNegative(it.id);
      let rate = InputHelper.formatMoney2(`${it.rate ?? 0}`, '$');
      let total = InputHelper.formatMoney2(`${it.total ?? 0}`, '$')
      if (isNegative) {
        total = `-${total}`;
      }
      return {
        name: MasterData.getServiceOptionName(it),
        qty: `${it.qty ?? 0}`,
        rate, total
      };
    })
  }

}